
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

footer {
  background-color: #24292e;
}


#circle {
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  animation: dash 2s ease-out forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 2000;
  }
}

#play {
  transform-origin: 50% 45%;
  animation: spin 2s ease-in-out forwards;
  
}

@keyframes spin {
  100% { transform : rotate(90deg) ;
    
}  
} 
#j{
  animation: fish 2s linear 2s 2 alternate;
}

@keyframes fish{
  100% { transform: translateY(200px);}

}

.text-teal {
  color:teal !important; }

a.text-teal:hover, a.text-teal:focus {
  color: teal !important; }

  .btn-teal {
    background-color: teal;
    color: white;
  }
 
  .circleThings {
    border-radius: 1rem;
  }